const Services = () => import("@/views/public/services/Services.vue");
const VirtualPlacement = () => import("@/views/public/services/VirtualPlacement.vue");
const Commissions = () => import("@/views/public/services/Commissions.vue");
const PresentationAssistance = () => import("@/views/public/services/PresentationAssistance.vue");
const PresentationAssistanceInquire = () =>
  import("@/views/public/services/PresentationAssistanceInquire.vue");
const VirtualPlacementInquire = () => import("@/views/public/services/VirtualPlacementInquire.vue");
const CommissionsInquire = () => import("@/views/public/services/CommissionsInquire.vue");

export default [
  {
    path: "/art-advisory",
    name: "ServicesPublic",
    component: Services,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/art-advisory/virtual-placement",
    name: "VirtualPlacement",
    component: VirtualPlacement,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/art-advisory/virtual-placement/inquire",
    name: "VirtualPlacementInquire",
    component: VirtualPlacementInquire,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/art-advisory/commissions",
    name: "Commissions",
    component: Commissions,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/art-advisory/commissions/inquire",
    name: "CommissionsInquire",
    component: CommissionsInquire,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/art-advisory/presentation-assistance",
    name: "PresentationAssistance",
    component: PresentationAssistance,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/art-advisory/presentation-assistance/inquire",
    name: "PresentationAssistanceInquire",
    component: PresentationAssistanceInquire,
    meta: {
      layout: "base",
    },
  },
];
