<template>
  <p class="copyright" tabindex="0">
    ©
    <span class="copyright copyright_author" :class="{ copyright_author_hidden: hiddenText }">
      <slot>Tom Grotta</slot>
    </span>
  </p>
</template>

<script>
export default {
  props: {
    hiddenText: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.copyright {
  font-family: $fontFrutigerNeue;
  color: $white;
  font-size: 1.6rem;
  font-weight: 400;
  @media (max-width: $xxs) {
    width: 100%;
  }
  &_author {
    width: max-content;
    font-size: 1.6rem;
    letter-spacing: -0.3px;
    line-height: 2.75;
    transition: all 0.5s ease;
    padding-left: 15px;
    @media (max-width: $xxs) {
      padding-left: 10px;
    }
    &_hidden {
      opacity: 0;
    }
  }
  &:focus:focus-visible .copyright_author {
    opacity: 1;
  }
  &:hover .copyright_author {
    opacity: 1;
  }
}
</style>
