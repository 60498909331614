import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import user from "./modules/private/user";
import general from "./modules/private/general";
import publicapi from "./modules/public/index";

import artists from "./modules/private/artists";
import publications from "./modules/private/publications";
import artworks from "./modules/private/artworks";

import exhibitions from "./modules/private/exhibitions";
import events from "./modules/private/events";

import news from './modules/private/news'
import pressreleases from './modules/private/pressreleases'
import looseThreads from './modules/private/looseThreads'
import memoriams from './modules/private/memoriams'

import arttextstyle from './modules/private/arttextstyle'

import filters from "./modules/private/filters";
import tags from "./modules/private/tags";
import comments from "./modules/private/comments";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: {
    adminLoader: true,
    breadcrumbs: [],
    menu: false
  },
  getters: {
    getAdminLoader(state) {
      return state.adminLoader;
    },
    getBreadcrumbs(state) {
      return state.breadcrumbs;
    },
    getMenuValue(state) { return state.menu }
  },
  mutations: {
    setAdminLoader(state, payload) {
      state.adminLoader = payload;
    },
    setBreadcrumbs(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs;
    },
    setOpenMenu(state, payload) { state.menu = payload }
  },
  actions: {},
  modules: {
    auth,
    user,
    general,
    artists,
    artworks,
    publications,
    filters,
    exhibitions,
    events,
    looseThreads,
    memoriams,
    pressreleases,
    news,
    arttextstyle,
    comments,
    tags,
    publicapi
  },
});
