var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"email-subscription__fade"}},[(_vm.subscriptionActive)?_c('div',{staticClass:"email-subscription__background"},[_c('ValidationObserver',{ref:"observer",staticClass:"email-subscription",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('img',{staticClass:"email-subscription__image",attrs:{"src":require("@/assets/images/subscription__image.png"),"alt":"art"}}),_c('div',{staticClass:"email-subscription__content"},[_c('IconButton',{staticClass:"email-subscription__close button--md button--outline-white",on:{"click":function($event){return _vm.$emit('hidenSubscription')}}},[_c('IconClose',{attrs:{"width":"18","height":"18"}})],1),_c('p',{staticClass:"email-subscription__text text-link"},[_vm._v(" Sign up for updates on exhibitions, artists and events. ")]),_c('ValidationProvider',{staticClass:"email-subscription__input",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SubscriptionInput',{attrs:{"label":"Name","placeholder":"Enter your name","validateError":errors[0]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"email-subscription__input",attrs:{"tag":"div","rules":{ required: true, email: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SubscriptionInput',{attrs:{"label":"Email","placeholder":"Enter your email","validateError":errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"email-subscription__checkbox",attrs:{"tag":"div","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseCheckbox',{staticClass:"checkbox--white",attrs:{"label":"I agree to the <a href='/terms-and-conditions'>Terms & Conditions</a> and <a href='/privacy-policy'>Privacy Policy</a>.","checked":_vm.form.agree,"validateError":errors[0]},on:{"input":function($event){_vm.form.agree = $event}}})]}}],null,true)}),_c('BaseButton',{staticClass:"email-subscription__button button--white",attrs:{"text":"SUBMIT","disabled":invalid || !_vm.form.agree},on:{"click":_vm.submit}})],1)]}}],null,false,1855218903)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }