<template>
  <div class="navigation">
    <div class="navigation__content">
      <div class="logo">
        <img :src="require(`@/assets/images/logo.png`)" alt="logo" />
      </div>
      <ul class="menu">
        <li class="menu__item" v-for="(route, id) in items" :key="`menu_item-${id}`">
          <router-link
            exact
            custom
            class="menu__item_link"
            :to="route.path"
            v-slot="{ navigate, isExactActive }"
          >
            <div
              @click="navigate"
              :class="[
                route.active && 'menu__item_link--active',
                isExactActive && 'menu__item_link--exact-active',
              ]"
            >
              <span class="menu__item_icon"><component :is="route.iconName" /></span>
              <p class="menu__item_text">{{ route.title }}</p>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="profile">
      <UploadImage
        :image="user.avatar"
        :initials="user.initials"
        :editable="false"
        @click="$router.push({ path: '/admin/profile' })"
      ></UploadImage>
      <div class="profile__text">
        <h6 @click="$router.push({ path: '/admin/profile' })">{{ user.name_abbreviation }}</h6>
        <button @click.prevent="apiLogoutUser" class="profile__text_logout">Logout</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import iconDashboard from "@/components/icons/IconDashboard.vue";
import iconCollection from "@/components/icons/IconCollection.vue";
import iconEvents from "@/components/icons/IconEvents.vue";
import iconContent from "@/components/icons/IconContent.vue";
import iconArttextstyle from "@/components/icons/IconArttextstyle.vue";

import UploadImage from "@/components/UploadImage.vue";
export default {
  components: {
    iconDashboard,
    iconCollection,
    iconEvents,
    iconContent,
    iconArttextstyle,
    UploadImage,
  },
  data() {
    return {
      items: [
        { id: 1, title: "Dashboard", path: "/admin", iconName: "iconDashboard" },
        { id: 2, title: "Collection", path: "/admin/collection", iconName: "iconCollection" },
        { id: 3, title: "Events", path: "/admin/events", iconName: "iconEvents" },
        { id: 4, title: "Content", path: "/admin/content", iconName: "iconContent" },
        { id: 5, title: "Arttextstyle", path: "/admin/arttextstyle", iconName: "iconArttextstyle" },
      ],
    };
  },
  created() {
    const { fullPath } = this.$route;
    const mainPath = fullPath;
    this.items = this.items.map(m => {
      return {
        ...m,
        active: mainPath === m.path || mainPath.split("/")[2] === m.path.split("/")[2],
      };
    });
  },
  computed: {
    ...mapGetters("user", { user: "getUserInfo" }),
  },
  watch: {
    $route(to) {
      const { fullPath } = to;
      const name = fullPath.substring(1).split("/")[1];
      const items = [];

      this.items = this.items.map(m => {
        let path = m.path.split("/")[2];
        if (fullPath === m.path || path === `${name}`) {
          items.push({ ...m, active: true });
        } else {
          items.push({ ...m, active: false });
        }
      });

      this.items = items;
    },
  },
  methods: {
    ...mapActions("auth", ["apiLogoutUser"]),
  },
};
</script>
<style lang="scss" scoped>
.navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 26rem;
  padding: 4rem 1.3rem 5.5rem 5.2rem;
  overflow: hidden;
  &__content {
    .logo {
      display: block;
      margin-bottom: 6rem;
    }
    .menu {
      &__item {
        margin-bottom: 2.6rem;
        cursor: pointer;
        &_icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 15px;
          color: $black;
        }
        &_text {
          @include type();
          color: $black;
          transition: all 0.3s ease-in-out;
          @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
            font-size: 14px;
          }
        }
        &_link {
          display: flex;
          align-items: center;
          ::v-deep g {
            fill: $black;
            transition: all 0.3s ease-in-out;
          }
          ::v-deep g.fill--transparent {
            fill: transparent;
            path {
              stroke: $black;
              transition: all 0.3s ease-in-out;
            }
          }
          ::v-deep g {
            use:nth-of-type(2) {
              stroke: $black;
              transition: all 0.3s ease-in-out;
            }
          }
          ::v-deep path.fill--filled {
            transition: all 0.3s ease-in-out;
          }
          &--active,
          &:hover {
            .menu__item_text {
              color: $blueDark;
            }
            ::v-deep path.fill--filled {
              fill: $blueDark;
              transition: all 0.3s ease-in-out;
            }
            ::v-deep g {
              fill: $blueDark;
            }
            ::v-deep g.fill--transparent {
              fill: transparent;
              path {
                stroke: $blueDark;
                transition: all 0.3s ease-in-out;
              }
            }
            ::v-deep g {
              use:nth-of-type(2) {
                stroke: $blueDark;
                transition: all 0.3s ease-in-out;
              }
            }
          }
        }
      }
    }
  }
  .profile {
    display: flex;
    align-items: center;
    &__text {
      margin-left: 11px;
      h6 {
        @include type($fs: 2rem, $lh: 1.2, $fw: 600);
        text-transform: capitalize;
        margin-bottom: 2px;
        color: $black;
        cursor: pointer;
        @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
          font-size: 16px;
        }
      }
      button {
        @include type($fs: 1.5rem, $lh: 1.25, $fw: 500);
        color: #868588;
        text-transform: capitalize;
        &:hover {
          color: $orange;
        }
        @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
