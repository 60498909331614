import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
    items: [],
    itemsHeaders: [
      {
        key: "title",
        title: "Title",
        sortable: true,
        className: ["bold"],
      },
      {
        key: "types",
        title: "Event Type",
        sortable: true,
        variables: "tag",
      },
      {
        key: "status",
        title: "Status",
        sortable: true,
        className: ["md"],
        variables: "status",
      },
      {
        key: "dates",
        title: "Dates",
        sortable: true,
        className: ["capitalize"],
      },
      {
        key: "edit",
        title: "Edit",
        sortable: false,
        className: ["xsm"],
        variables: "icon",
        icon: "IconEdit",
      },
      {
        key: "delete",
        title: "Delete",
        sortable: false,
        className: ["xsm"],
        variables: "icon",
        icon: "IconDelete",
      },
    ],
    searchedItems: [],
    item: null,
    changesLoading: false,
    hasMorePages: false,
    page: 1,
    serverError: {
      slug: null,
      start_date: null,
      end_date: null,
    },
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getItemsHeaders(state) {
      return state.itemsHeaders;
    },
    getItems(state) {
      return state.items;
    },
    getItem(state) {
      return state.item;
    },
    getChangesLoading(state) {
      return state.changesLoading;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getServerError(state) {
      return state.serverError;
    },
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setItems(state, payload) {
      let items = [];
      items = payload.map(el => {
        return {
          ...el,
          types: { main: el.types, additional: [] },
          preview_image: el.home_page_image ? el.home_page_image : false,
          subtitle: el.dates,
          edit: true,
          view: true,
          tag: el.types,
        };
      });
      if (state.page > 1) {
        state.items = state.items.concat(items);
      } else {
        state.items = items;
      }
    },
    initialItem(state) {
      state.item = null;
    },
    setItem(state, payload) {
      state.item = {
        ...payload,
        status: payload.status ? 1 : 0,
        featured: Boolean(+payload.featured),
      };
    },
    setChangesLoading(state, payload) {
      state.changesLoading = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setError(state, payload) {
      let serverError = state.serverError;
      if (payload != null) {
        for (let i in payload) {
          serverError[i] = payload[i][0];
        }
        state.serverError = serverError;
      } else
        state.serverError = {
          slug: payload,
          start_date: payload,
          end_date: payload,
        };
    },
  },
  actions: {
    async apiGetEvents(
      { commit },
      {
        status = "",
        types = "",
        sort = "title",
        order = "asc",
        page = "1",
        search = "",
        search_type = "",
        limit = "",
      }
    ) {
      let success = false;
      commit("setLoading", true);
      commit("setHasMorePages", false);
      let query = {
        status,
        types,
        sort,
        order,
        page,
        search,
        search_type,
        limit,
      };
      let queryStr = "";
      for (let key in query) {
        if (query[key] != "") {
          queryStr += `${key}=${query[key]}&`;
        }
      }
      await Vue.axios
        .get(`/api/admin/events?${queryStr}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setPage", page);
          commit("setHasMorePages", data.hasMorePages);
          commit("setItems", data.response);
        })
        .finally(() => {
          commit("setLoading", false);
        });
      return success;
    },
    async apiPostEvent({ commit }, payload) {
      let result = false;
      let data = {
        ...payload,
        status: payload.status != null ? payload.status : "0",
      };
      commit("setChangesLoading", true);
      await Vue.axios
        .post("/api/admin/event", data)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.detail);
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiGetEvent({ commit }, id) {
      let result = false;
      await Vue.axios
        .get(`/api/admin/event/${id}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setItem", data.response);
        });
      return result;
    },
    async apiPutEvent({ commit }, payload) {
      commit("setChangesLoading", true);
      let result = false;
      await Vue.axios
        .put(`/api/admin/event/${payload.id}`, payload)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.detail);
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiDeleteEvent({ commit }, id) {
      let result = false;
      await Vue.axios
        .delete(`/api/admin/event/${id}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        });
      return result;
    },
  },
};
