const EventsLandingPage = () => import("@/views/public/events/EventsLandingPage.vue");
const ListOfExhibitions = () => import("@/views/public/events/ExhibitionsPage.vue");
const SpecificExhibitionPage = () => import("@/views/public/events/SpecificExhibitionPage.vue");

const ListOfEvents = () => import("@/views/public/events/EventsPage.vue");
const SpecificEventPage = () => import("@/views/public/events/SpecificEventPage.vue");

export default [
  {
    path: "/events",
    name: "EventsLandingPage",
    component: EventsLandingPage,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/exhibitions",
    name: "ListOfExhibitions",
    component: ListOfExhibitions,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/exhibitions/:slug",
    name: "SpecificExhibitionPage",
    component: SpecificExhibitionPage,
    meta: {
      layout: "base",
      shareButton: true,
    },
  },
  {
    path: "/events/events",
    name: "ListOfEvents",
    component: ListOfEvents,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/events/events/:slug",
    name: "SpecificEventPage",
    component: SpecificEventPage,
    meta: {
      layout: "base",
      shareButton: true,
    },
  },
];
