import Vue from "vue";
import VueRouter from "vue-router";

const Home = () => import("@/views/public/Home.vue");
const About = () => import("@/views/public/About.vue");
const ContactUs = () => import("@/views/public/ContactUs.vue");
const NotFound = () => import("@/views/NotFound.vue");
const Content = () => import("@/views/public/Content.vue");
const ShippingReturns = () => import("@/views/public/ShippingReturns.vue");
const PrivacyPolicy = () => import("@/views/public/PrivacyPolicy.vue");
const TermsOfService = () => import("@/views/public/TermsOfService.vue");

import auth from "./auth";
import admin from "./admin";

import events from "./events.js";
import library from "./library.js";
import shop from "./shop.js";

import artists from "./artists.js";
import services from "./services.js";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  ...events,
  ...artists,
  ...shop,
  ...library,
  ...services,
  ...shop,
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/contact",
    name: "ContactUs",
    component: ContactUs,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/content",
    name: "Сontent",
    component: Content,
    meta: {
      layout: "base",
      buttonClose: true,
    },
  },
  {
    path: "/shipping-and-returns",
    name: "ShippingReturns",
    component: ShippingReturns,
    meta: {
      layout: "base",
      menu: false,
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: {
      layout: "base",
      menu: false,
    },
  },
  {
    path: "/terms-and-conditions",
    name: "TermsOfService",
    component: TermsOfService,
    meta: {
      layout: "base",
      menu: false,
    },
  },
  ...auth,
  ...admin,
  {
    path: "/404",
    name: "404",
    component: NotFound,
    meta: {
      layout: "empty",
    },
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
