export function infiniteScroll(el) {
  let result = false;
  const listElm = document.querySelector(el);
  const sizeScrollActive = 100;
  if (listElm.scrollTop + listElm.clientHeight + sizeScrollActive >= listElm.scrollHeight) {
    result = true;
  }
  return result;
}

export function initials(name, lastName) {
  let letterName = "",
    letterLastName = "";
  letterName = name[0].toUpperCase();
  if (lastName) {
    letterLastName = lastName[0].toUpperCase();
  }
  return letterName + letterLastName;
}
