import Vue from "vue";
export default {
  namespaced: true,
  state: {
    serverError: {
      title: null,
    },
    searchResult: {},
    searchTagResult: [],
    hasMorePages: false,
    tagName: "",
  },
  getters: {
    getServerError(state) {
      return state.serverError;
    },
    getSearchResult(state) {
      return state.searchResult;
    },
    getSearchTagResult(state) {
      return state.searchTagResult;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getTagName(state) {
      return state.tagName;
    },
  },
  mutations: {
    setError(state, payload) {
      let serverError = state.serverError;
      if (payload != null) {
        for (let i in payload) {
          serverError[i] = payload[i][0];
        }
        state.serverError = serverError;
      } else
        state.serverError = {
          title: payload,
        };
    },
    setSearchResult(state, payload) {
      if (
        payload.articles.count === 0 &&
        payload.artists.count === 0 &&
        payload.artworks.count === 0 &&
        payload.events.count === 0
      ) {
        state.searchResult = {
          result: false,
          ...payload,
        };
      } else {
        payload.articles.list.map(item => {
          switch (item.type) {
            case "arttextstyle":
              item.slug = "/media/arttextstyle/" + item.slug;
              break;
            case "press release":
              item.slug = "/media/press-releases/" + item.slug;
              break;
            case "in the news":
              item.slug = "/media/in-the-news/" + item.slug;
              break;
          }
        });
        payload.events.list.map(item => {
          switch (item.type) {
            case "event":
              item.slug = "/events/events/" + item.slug;
              break;
            case "exhibition":
              item.slug = "/exhibitions/" + item.slug;
              break;
          }
        });
        state.searchResult = {
          result: true,
          ...payload,
        };
      }
    },
    setSearchTagResult(state, { data, page }) {
      if (page === 1) state.searchTagResult = data;
      else state.searchTagResult = state.searchTagResult.concat(data);
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setTagName(state, payload) {
      state.tagName = payload;
    },
  },
  actions: {
    async apiSearch({ commit }, search) {
      let success = false;
      await Vue.axios
        .get(`/api/public/search?search=${search}`)
        .then(res => res.data)
        .then(data => {
          commit("setSearchResult", data.response);
          success = true;
          commit("setError", null);
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.details.search[0]);
          }
        });
      return success;
    },
    async apiSearchTag({ commit }, { tag_id, page }) {
      let success = false;
      await Vue.axios
        .get(`/api/public/search-by-tag?tag_id=${tag_id}&page=${page}`)
        .then(res => res.data)
        .then(data => {
          commit("setSearchTagResult", { data: data.response, page });
          commit("setHasMorePages", data.hasMorePages);
          commit("setTagName", data.tagName);
          success = true;
          commit("setError", null);
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.details.message);
          }
        });
      return success;
    },
  },
};
